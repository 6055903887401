<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("EVENT_MANAGEMENT.FILTER_EVENTS") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <!-- FILTER -->
          <div class="row">
            <!-- Energy type -->
            <b-input-group
              data-app
              :prepend="$t('FILTER.ENERGY_TYPE_LABEL')"
              class="col pb-0 pl-0 pr-0"
            >
              <v-autocomplete
                class="input-group custom-select"
                v-model="type"
                :items="energyTypes"
                @blur="onChangeEnergyType"
                autocomplete="not-today"
                solo
                flat
                dense
                :no-data-text="$t('FILTER.NO_DATA')"
              ></v-autocomplete>
            </b-input-group>
          </div>

          <div class="row mt-5 mb-10">
            <!-- CUI -->
            <b-input-group
              data-app
              :prepend="$t('FILTER.COMPANY_CUI_LABEL')"
              class="col-6 pb-0 pl-0"
            >
              <v-autocomplete
                class="input-group custom-select"
                v-model="company"
                :items="organizations"
                autocomplete="not-today"
                item-text="cui"
                solo
                flat
                dense
                return-object
              ></v-autocomplete>
            </b-input-group>
            <!-- Company name -->
            <b-input-group
              data-app
              :prepend="$t('FILTER.COMPANY_NAME_LABEL')"
              class="col pb-0 pr-0"
            >
              <v-autocomplete
                class="input-group custom-select"
                v-model="company"
                :items="organizations"
                autocomplete="not-today"
                item-text="name"
                solo
                flat
                dense
                return-object
              ></v-autocomplete>
            </b-input-group>
          </div>

          <!-- PROCESS -->
          <div class="row">
            <button @click="getEvents" class="btn btn-primary btn-block">
              {{ $t("EVENT_MANAGEMENT.FILTER_EVENTS") }}
            </button>
          </div>
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <div class="card card-custom gutter-b" v-if="isFiltered">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("EVENT_MANAGEMENT.EVENTS") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <div class="card-body">
        <div class="text-center" v-if="inProgress">
          <v-progress-circular
            indeterminate
            color="#3699FF"
            size="50"
          ></v-progress-circular>
        </div>

        <v-data-table
          v-else
          :headers="tableHeader"
          :items="events"
          :search="searchEvent"
          :loading="!hasLoadedEvents"
          :loading-text="$t('EVENT_MANAGEMENT.LOADING')"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- EDIT/ADD NEW DIALOG -->
              <v-dialog v-model="dialog" max-width="500px">
                <!-- ADD NEW BUTTON -->
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="btn btn-primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="addEvent"
                  >
                    {{ $t("EVENT_MANAGEMENT.ADD_NEW") }}
                  </button>
                </template>

                <!-- ADD EVENT DIALOG -->
                <EventModal
                  :title="$t('EVENT_MANAGEMENT.ADD_NEW')"
                  :details="editedEvent"
                  @close="closeEventModal"
                  @save="saveEventModal"
                  ref="eventModal"
                ></EventModal>
              </v-dialog>

              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-text-field
                v-model="searchEvent"
                :label="$t('EVENT_MANAGEMENT.SEARCH')"
                single-line
                hide-details
              ></v-text-field>

              <!-- DELETE DIALOG -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">{{
                    $t("EVENT_MANAGEMENT.CONFIRM_DELETE")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <button
                      class="btn btn-secondary mr-5"
                      @click="closeDeleteDialog"
                    >
                      {{ $t("EVENT_MANAGEMENT.CANCEL") }}
                    </button>
                    <button class="btn btn-primary" @click="deleteEventConfirm">
                      {{ $t("EVENT_MANAGEMENT.OK") }}
                    </button>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- ACTION COLUMNS -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2 text-primary" @click="editEvent(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="text-danger" @click="deleteEvent(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <!--end::Card Body-->
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("EVENT_MANAGEMENT.PUBLIC_HOLIDAYS") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <div class="card-body">
        <div class="row">
          <div class="col mr-6">
            <!-- year picker -->
            <b-input-group
              :prepend="$t('FILTER.PERIOD.YEAR')"
              class="col pb-0 pr-0"
            >
              <b-form-select
                v-model="holidayYear"
                :options="years"
                @change="onChangeYear"
              ></b-form-select>
            </b-input-group>
            <v-data-table
              :headers="holidayHeaders"
              :items="holidays"
              :items-per-page="25"
              class="elevation-1"
              :hide-default-footer="true"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small @click="deletePublicHoliday(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
          <div class="col-6">
            <p class="h6">{{ $t("EVENT_MANAGEMENT.DATE") }}</p>

            <b-form-textarea
              id="textarea"
              v-model="publicHolidays"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <button
              class="btn btn-primary btn-block mt-4"
              @click="addPublicHolidays"
            >
              {{ $t("EVENT_MANAGEMENT.SAVE") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventModal from "@/view/content/modals/EventModal.vue";
import EventsService from "@/core/services/events.service";
import ApiService from "@/core/services/api.service";

export default {
  name: "ManageEvents",
  components: { EventModal },
  data() {
    return {
      holidayYear: "2022",
      years: [],
      holidays: [],
      holidayHeaders: [],
      date: "",
      type: "",
      publicHolidays: "",
      company: {},
      organizations: [],
      gasOrganizations: [],
      energyOrganizations: [],
      action: "",
      events: [],
      inProgress: false,
      isFiltered: false,
      searchEvent: "",
      tableHeader: [],
      hasLoadedEvents: true,
      editedEvent: {
        id: 0,
        companyName: "",
        companyCui: "",
        eventType: "",
        value: 0,
        reason: "",
        energyType: "",
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
      },
      defaultEvent: {
        id: 0,
        companyName: "",
        companyCui: "",
        eventType: "",
        value: 0,
        reason: "",
        energyType: "",
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
      },
      dialog: false,
      dialogDelete: false,
      eventTypes: [],
      energyTypes: [],
    };
  },
  methods: {
    async deletePublicHoliday(item) {
      await EventsService.removePublicHoliday(item.day);
      await this.getPublicHolidays();
    },
    onChangeYear(year) {
      this.getPublicHolidays(year);
    },
    onChangeEnergyType() {
      if (this.type == this.$t("FILTER.ENERGY.ENERGY")) {
        this.organizations = this.energyOrganizations;
      } else {
        this.organizations = this.gasOrganizations;
      }
    },
    addEvent() {
      this.action = "add";
      this.editedEvent.companyName = this.company.name;
      this.editedEvent.companyCui = this.company.cui;
      this.editedEvent.energyType = this.type;

      this.dialog = true;
    },
    editEvent(item) {
      this.editedEvent = Object.assign({}, item);
      this.action = "edit";
      this.dialog = true;
    },
    deleteEvent(item) {
      this.editedEvent = Object.assign({}, item);
      this.action = "delete";
      this.dialogDelete = true;
    },
    deleteEventConfirm() {
      this.closeDeleteDialog();
    },
    closeEventModal() {
      this.action = "";
      this.dialog = false;
      this.$nextTick(() => {
        this.editedEvent = Object.assign({}, this.defaultEvent);
      });
    },
    async closeDeleteDialog() {
      if (this.action == "delete") {
        await EventsService.deleteEvent(this.editedEvent.id);
        await this.getEvents();
      }
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedEvent = Object.assign({}, this.defaultEvent);
        this.action = "";
      });
    },
    async saveEventModal() {
      let newEvent = this.$refs.eventModal.details;
      if (this.action == "add") await EventsService.addEvent(newEvent);
      else if (this.action == "edit") await EventsService.updateEvent(newEvent);
      await this.getEvents();
      this.closeEventModal();
      this.action = "";
    },
    async getEvents() {
      this.isFiltered = true;
      this.inProgress = true;

      let filter = {
        type: this.type,
        cui: this.company ? this.company.cui : "",
      };

      this.events = await EventsService.getEvents(filter);
      this.inProgress = false;
    },
    async addPublicHolidays() {
      await EventsService.addPublicHolidays(this.publicHolidays);
      await this.getPublicHolidays();
    },
    getEnergyCompanies() {
      var _this = this;

      ApiService.post("/companies/energy").then((response) => {
        if (_this.type.toLowerCase() == "electric") {
          _this.organizations = response.data;
        }
        _this.energyOrganizations = response.data;
      });
    },
    getGasCompanies() {
      var _this = this;

      ApiService.post("/companies/gas").then((response) => {
        if (_this.type.toLowerCase() == "gas") {
          _this.organizations = response.data;
        }
        _this.gasOrganizations = response.data;
      });
    },
    async getPublicHolidays(year) {
      this.holidays = await EventsService.getPublicHolidays(year);
    },
  },
  watch: {
    dialog(val) {
      val || this.closeEventModal();
    },
    dialogDelete(val) {
      val || this.closeDeleteDialog();
    },
  },

  mounted() {
    this.years = [];

    let currentYear = new Date().getFullYear();
    for (let i = 2000; i <= currentYear; i++) {
      this.years.push(i);
    }

    this.getPublicHolidays(currentYear);

    this.holidayHeaders = [
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.HOLIDAY"),
        value: "day",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.ACTIONS"),
        value: "actions",
        sortable: false,
      },
    ];

    this.tableHeader = [
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.CLIENT_NAME"),
        value: "companyName",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.CLIENT_CUI"),
        value: "companyCui",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.ENERGY_TYPE"),
        value: "energyType",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.EVENT_TYPE"),
        value: "eventType",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.REASON"),
        value: "reason",
      },
      { text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.VALUE"), value: "value" },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.START_DATE"),
        value: "startDate",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.START_HOUR"),
        value: "startHour",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.END_DATE"),
        value: "endDate",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.END_HOUR"),
        value: "endHour",
      },
      {
        text: this.$t("EVENT_MANAGEMENT.TABLE_HEADER.ACTIONS"),
        value: "actions",
        sortable: false,
      },
    ];

    this.getGasCompanies();
    this.getEnergyCompanies();

    this.energyTypes = [
      this.$t("FILTER.ENERGY.ENERGY"),
      this.$t("FILTER.ENERGY.GAS"),
    ];

    this.type = this.energyTypes[0];
    this.onChangeEnergyType();
  },
};
</script>

<style>
.input-group > .input-group-prepend {
  flex: 0 0 20%;
}
.input-group .input-group-text {
  width: 100%;
}
</style>
